.chatroom-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 2rem;
      color: #333;
    }
  
    .chatroom-info {
      text-align: center;
      margin-bottom: 20px;
  
      h2 {
        margin: 0;
        font-size: 1.5rem;
        color: #555;
      }
  
      p {
        margin: 5px 0;
        font-size: 0.9rem;
        color: #777;
      }
    }
  
    .chat-messages {
      max-height: 400px;
      overflow-y: auto;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 20px;
  
      .chat-message {
        padding: 8px;
        border-bottom: 1px solid #eee;
  
        &:last-child {
          border-bottom: none;
        }
  
        strong {
          color: #007bff;
        }
  
        .timestamp {
          display: block;
          font-size: 0.8rem;
          color: #aaa;
          margin-top: 4px;
        }
      }
    }
  
    .chat-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      input {
        flex-grow: 1;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-right: 10px;
      }
  
      button {
        padding: 10px 20px;
        font-size: 1rem;
        background-color: #007bff;
        border: none;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  