body {
    margin: 0;
    padding: 0;
    background-color: #e3f2fd; // 연한 블루 배경
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-optical-sizing: auto;
    font-style: normal;
    // overflow: hidden; // 전체 페이지에서 스크롤을 막음
}

.container {
    width: 100%;
    height: 100vh; // 전체 뷰포트 높이
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    color: #0d47a1; // 진한 블루 글자 색상
}

h1 {
    font-size: 3rem; // 글자 크기 확대
    color: #1e3a8a; // 더 진한 블루 색상
    margin-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); // 그림자 효과
    background: linear-gradient(90deg, #1e3a8a, #64b5f6); // 그라데이션 효과
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.project-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; // 중앙 정렬
    align-items: center; // 수직 중앙 정렬
    width: 100%;
    max-width: 1200px;
}

.project-item {
    width: calc(33.33% - 40px); // 3열 레이아웃에서 간격을 고려한 너비
    max-width: 350px;
    margin: 20px; // 간격 추가
    display: flex;
    justify-content: center; // 수평 중앙 정렬
    align-items: center; // 수직 중앙 정렬
}
