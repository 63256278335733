/* TrendChat.css */
.trendchat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .trendchat-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .trendchat-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .trendchat-timestamp {
    font-size: 1rem;
    color: #999;
    margin-bottom: 20px;
  }
  