.entry-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    max-width: 600px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.entry-item {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.entry-name {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.entry-content {
    font-size: 1em;
    color: #666;
    text-align: left;
}

.createDate {
    font-size: 0.6em;
    color: #999;
    margin-bottom: 10px;
}