.guestbook {
    text-align: center;
    margin: 20px;
}

.post-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-form input,
.post-form textarea {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
}

.post-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
}

.post-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.post-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    margin: 10px 0;
    text-align: left;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.name {
    font-weight: bold;
}

.content {
    margin-top: 5px;
    line-height: 1.6;
}

.pagination {
    margin-top: 20px;
}

.pagination-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.pagination-item {
    margin: 0 5px;
}

.pagination-link {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f8f9fa;
}
