.entry-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.entry-form-input,
.entry-form-textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.entry-form-button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.entry-form-button:hover {
    background-color: #0056b3;
}
