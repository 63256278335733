.project-box {
    position: relative;
    display: block;
    width: 100%;
    max-width: 350px;
    padding: 20px;
    margin: 20px auto; // 가운데 정렬
    border-radius: 8px;
    background: #64b5f6;
    color: #fff;
    text-align: left;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);

        .project-address {
            opacity: 1;
        }
    }

    .project-title {
        font-size: 1.5rem;
        margin-top: 10px; // 상단 여백 추가
        color: #0d47a1;
        transition: color 0.2s ease;
    }

    .project-description {
        font-size: 1rem;
        margin-bottom: 15px; // 설명과 날짜 간격 조정
        color: #0d47a1;
    }

    .project-address {
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .project-duration {
        font-size: 0.8rem;
        color: #444; // 진한 회색으로 변경
        margin-top: 10px; // 날짜와 inactive 아이콘 간격 조정
    }

    .permission-icon {
        position: absolute;
        top: 15px; // 상단 여백 추가
        right: 10px;
        width: 20px;
        height: 20px;
        background: url('../imgs/permission_icon.png') no-repeat center center / contain;
    }

    .inactive-icon {
        position: absolute;
        top: 15px; // 상단 여백 추가
        right: 10px;
        width: 20px;
        height: 20px;
        background: url('../imgs/denied_icon.png') no-repeat center center / contain;
    }

    &.inactive {
        opacity: 0.6;

        .project-title {
            text-decoration: line-through;
        }

        .inactive-icon {
            display: block;
        }

        .permission-icon {
            display: none; // inactive일 때 permission 아이콘 숨김
        }
    }
}
