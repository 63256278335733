.usage-box {
    gap: 10px; /* 도넛과 퍼센트 사이의 간격 */
}

/* ✅ 기본적인 차트 컨테이너 스타일 */
.chart-container {
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.percentage-container {
    // height: 10%;
    font-size: 1.5rem; 
    font-weight: bold; 
    color: #212529; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    // margin-top: -10px; /* 💡 차트와의 간격을 미세하게 조절 */
}
