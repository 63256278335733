.container {
    // width: 100%;
    display: flex;
    flex-wrap: wrap; /* 여러 컴포넌트가 여러 줄에 배치되도록 */
    justify-content: center; /* 가로로 중앙 정렬 */
    gap: 20px; /* 컴포넌트 간 간격 */
}

.container > div {
    width: 300px; 
    height: 220px; /* 높이를 늘려서 공간 확보 */
    padding: 20px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
}

h3 {
    // margin-bottom: 15px;
    font-size: 1.5rem;
    color: #343a40;
}

p {
    margin: 10px 0;
    font-size: 1rem;
    color: #495057;
}

strong {
    font-weight: bold;
    color: #212529;
}
