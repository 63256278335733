/* src/pages/Joke.css */

/* 기본 레이아웃 */
.joke-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
}

.title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.joke-button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #ff4757;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.joke-button:hover {
    background-color: #ff6b81;
}

.loading {
    font-size: 1.5em;
    color: #333;
}

/* 미디어 쿼리: 모바일 대응 */
@media (max-width: 768px) {
    .title {
        font-size: 2em;
    }

    .joke-button {
        padding: 8px 16px;
        font-size: 1em;
    }
}
